<template>
  <div>
    <PageHeader
      :back="false"
      :icon="$t('OFFICE.ICON')"
      :title="$t('OFFICE.TITLE')"
    >
      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="#CFD8DC"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="showEdit = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.EDIT') }}
      </v-tooltip>
    </PageHeader>

    <Side
      component="forms/office/compact/FormOfficeCompact"
      :value="showEdit"
      :ids="office_id"
      @close="closeEdit"
      @update="updatePage"
    />

    <Loading :value="loading" />

    <v-row class="ma-5" align="center">
      <v-img
        max-width="250"
        min-width="250"
        class="office_avatar"
        :src="office.images || '/img/empty-image.png'"
      />
      <v-col>
        <div class="ml-5 mt-3">
          <h2>{{ office.title }}</h2>
          <p>
            {{ office.country }} - {{ $t('COMMON.CREATED') }} {{ $moment(office.created_at).fromNow() }}
            - {{ $t('INPUT.UPDATED') }} {{ $moment(office.updated_at).fromNow() }}
          </p>
          <p>{{ office.company.title }} {{ $t('INPUT.COMPANY') }}</p>
          <p>{{ office.description }}</p>
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-2 my-0">
      <v-col md="4" sm="6">
        <v-list outlined class="px-5" rounded>
          <v-list-item-title><h3>{{ $t('OFFICE.CONTACT') }}</h3><br></v-list-item-title>
          <v-list-item v-if="office.contact_name"><span>{{ office.contact_name }}</span></v-list-item>
          <v-list-item v-if="office.contact_email"><span>{{ office.contact_email }}</span></v-list-item>
          <v-list-item v-if="office.contact_address"><span>{{ office.contact_address }}</span></v-list-item>
        </v-list>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import PageHeader from "@/components/PageHeader"
import Loading from "@/components/Loading"
import Side from '@/components/Side'

export default {
  name: 'PageSettingsManagerOffice',
  components: {
    PageHeader,
    Loading,
    Side,
  },
  data: () => ({
    showEdit: false,
    valid: false,
    loading: false,
    office_id: [],
    office: {
      title: undefined,
      description: undefined,
      country: undefined,
      created_at: undefined,
      updated_at: undefined,
      contact_name: undefined,
      contact_email: undefined,
      contact_address: undefined,
      images: undefined,
      company: []
    }
  }),
  async created() {
    try {
      this.loading = true
      this.loadOffice()
    } finally {
      this.loading = false
    }
  },
  methods: {
    async loadOffice () {
      const userSession = await this.$services.api_user.user_session.check()
      this.office_id.push(userSession.data.user.office.id)

      const response = await this.$services.api_user.office.search({where: { id: this.office_id[0] }})
      this.office.title = response.data[0].title
      this.office.description = response.data[0].description
      this.office.country = response.data[0].country.title
      this.office.created_at = response.data[0].created_at
      this.office.updated_at = response.data[0].updated_at
      this.office.contact_name = response.data[0].contact_name
      this.office.contact_email = response.data[0].contact_email
      this.office.contact_address = response.data[0].contact_address
      this.office.images = response.data[0].images
      this.office.company = response.data[0].company
    },

    closeEdit () {
      this.showEdit = false
    },

    updatePage () {
      this.$store.dispatch('app/init')
    },
  }
}
</script>

<style scoped>
.office_avatar {
  border-radius: 3%;
  border: 1px solid black;
}
</style>